<template>
  <div class="softwareComponentLoginEdit">
    <form>
      <div class="form-group">
        <h4 class="m-0 mt-3">
          Login Data
        </h4>
        <hr class="m-0 mb-3">
        <label>{{ $t('type') }}</label>
        <Multiselect
          id="type"
          v-model="softwareComponentLogin.type"            
          :options="types"
          :close-on-select="true"
          class="mb-3"
        />
        <hr class="m-0 mb-3">
        <label>Access restricted to role</label>
        <LoadingPlaceholder v-if="roleLoading" />
        <Multiselect
          id="role"
          v-model="softwareComponentLogin.role"            
          :options="roles"
          :close-on-select="true"
          class="mb-3"
        />
        <template v-if="softwareComponentLogin.role">
          <p
            v-if="!authenticationHasRole(softwareComponentLogin.role)"
            style="color:red;"
          >
            You don't have this Role, therefore this Entry will not be visible to you.
          </p>
        </template>
        <hr class="m-0 mb-3">
        <label>{{ $t('username') }}</label>
        <input
          v-model="softwareComponentLogin.username"
          v-focus
          :class="['form-control', { 'is-invalid': $validator.errors.has('Username') }]"
          type="text"
        >
        <hr class="m-0 mb-3">
        <label>{{ $t('password') }}</label>
        <input
          v-model="password"
          :class="['form-control', { 'is-invalid': $validator.errors.has('Password') }]"
          type="text"
        >
        <hr class="m-0 mb-3">
      </div>
      <button
        class="btn btn-success"
        @click.prevent="generatePassword()"
      >
        <font-awesome-icon
          class="mr-2"
          icon="key"
        />Generate Password
      </button>
      <div class="m-0 mb-3" />
      <label>{{ $t('description') }}</label>
      <textarea
        id="description"
        v-model="softwareComponentLogin.description"
        rows="4"
        class="form-control"
      />
      <hr class="m-0 mb-3">
      <button
        class="btn btn-primary float-right"
        :disabled="!password || !softwareComponentLogin.role"
        @click.prevent="updateSoftwareComponentLogin()"
      >
        <font-awesome-icon
          class="mr-2"
          icon="check"
        />{{ $t('save') }}
      </button>
      <div class="clearfix" />
    </form>
  </div>
</template>

<script>
import { errorMixin } from '@/mixins/errorMixin.js';
import { authenticationMixin } from '@/mixins/authenticationMixin';

export default {
  name: "SoftwareComponentLoginEdit",
  components: {
    Multiselect: () => import('vue-multiselect')
  },
  mixins: [
    errorMixin,
    authenticationMixin
  ],
  props: {
    softwareComponentLogin: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      types: ['System', 'Application', 'API', 'Database'],
      roles: [],
      password: null,
      roleLoading: false
    }
  },
  created () {
    this.password = this.softwareComponentLogin.password;
    this.getLoginRoles();
  },
  methods: {
    async updateSoftwareComponentLogin () {
      this.softwareComponentLogin.password = this.password;
      await this.axios.put('/SoftwareComponent/UpdateSoftwareComponentLogin', this.softwareComponentLogin)
        .then((response) => {
          if(response) {
           this.$snotify.success(this.$t('softwareComponent.loginDataUpdatedSuccessfully')); 
          }
          
          this.$emit("reload");
          this.error_clear();
        })
        .catch((error) => {
          this.error_clear();
          this.error_validate(error);
        });
    },
    getLoginRoles () {
      this.roleLoading = true;
      this.axios.get(`/SoftwareComponent/GetLoginDataRoles`)
      .then((response) => {
        if (response == null) {
          return;
        }
        if (response.data == null) {
          return;
        }
        this.roles = response.data;
      })
      .finally(() => {
        this.roleLoading = false;
      });
    },
    generatePassword () {
      let length = 15;
      let wishlist = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz_!+-';
      this.password = Array.from(crypto.getRandomValues(new Uint32Array(length)))
        .map((x) => wishlist[x % wishlist.length])
        .join('');
      
    }
  }
}
</script>

<style scoped>
.softwareComponentLoginEdit .progress {
  display: flex;
  flex: 1 1 auto;
  width: 1%;
  height: 20px;
  font-size: 1rem;
}
</style>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
